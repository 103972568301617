<template>
  <div class="container-fluid">
    <card class="no-border-card">
      <div class="row cards-grid">
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_AUCTIONS)"
          :to="{ name: 'List Auctions' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <div class="grid-card-content">
            <a class="menu-grid-item" :href="href" @click="navigate">
              <span
                class="shortcut-media avatar avatar-xl rounded-circle bg-info"
              >
                <i class="ni ni-world-2 ni-2x"></i>
                <!--              <i class="fab fa-accusoft ni-2x"></i>-->
              </span>
              <small>{{ $t("COMMON.AUCTIONS") }}</small>
            </a>
          </div>
        </router-link>

        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_VEHICLES)"
          :to="{ name: 'List Vehicles' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <div class="grid-card-content">
            <a class="menu-grid-item" :href="href" @click="navigate">
              <span
                class="shortcut-media avatar avatar-xl rounded-circle bg-info"
              >
                <i class="fab fa-accusoft ni-2x"></i>
              </span>
              <small>{{ $t("COMMON.VEHICLES") }}</small>
            </a>
          </div>
        </router-link>

        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_EVENTS)"
          :to="{ name: 'List Events' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <div class="grid-card-content">
            <a class="menu-grid-item" :href="href" @click="navigate">
              <span
                class="shortcut-media avatar avatar-xl rounded-circle bg-info"
              >
                <i class="ni ni-building ni-2x"></i>
              </span>
              <small>{{ $t("COMMON.EVENTS") }}</small>
            </a>
          </div>
        </router-link>

        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_TRANSACTIONS)"
          :to="{ name: 'List Transactions' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <div class="grid-card-content">
            <a class="menu-grid-item" :href="href" @click="navigate">
              <span
                class="shortcut-media avatar avatar-xl rounded-circle bg-info"
              >
                <i class="ni ni-2x ni-money-coins"></i>
              </span>
              <small>{{ $t("COMMON.TRANSACTIONS") }}</small>
            </a>
          </div>
        </router-link>

        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_COLLECTES)"
          :to="{ name: 'List Collectes' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <div class="grid-card-content">
            <a class="menu-grid-item" :href="href" @click="navigate">
              <span
                class="shortcut-media avatar avatar-xl rounded-circle bg-info"
              >
                <i class="ni ni-2x ni-money-coins"></i>
              </span>
              <small>{{ $t("COMMON.COLLECTES") }}</small>
            </a>
          </div>
        </router-link>

        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_RECLAMATIONS)"
          :to="{ name: 'List Reclamations' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <div class="grid-card-content">
            <a class="menu-grid-item" :href="href" @click="navigate">
              <span
                class="shortcut-media avatar avatar-xl rounded-circle bg-info"
              >
                <i class="ni ni-2x ni-single-copy-04"></i>
              </span>
              <small>{{ $t("COMMON.RECLAMATIONS") }}</small>
            </a>
          </div>
        </router-link>
      </div>
    </card>
  </div>
</template>

<script>
export default {
  layout: "DashboardLayout",

  components: {},

  mixins: [],

  data() {
    return {};
  },

  created() {},

  methods: {},
};
</script>
